import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "input", "counter", "submitButton", "deleteField"]

  connect() {
    this.validateAll()
    this.updateCounters()
    this.checkExistingSerials()
  }

  async checkExistingSerials() {
    const inputs = this.inputTargets
    for (const input of inputs) {
      if (input.value) {
        await this.validateSerial(input)
      }
    }
  }

  async validateField(event) {
    const input = event.target
    input.value = input.value.trim().toUpperCase()
    await this.validateSerial(input)
    this.validateAll()
  }

  async validateSerial(input) {
    if (!input.value) return

    try {
      const response = await fetch(`/api/serial_numbers/check?serial=${input.value}`)
      const data = await response.json()

      // Check if this is an existing serial that hasn't been changed
      const serialIdField = input.name.match(/existing\[(\d+)\]/)
      const isExistingUnchanged = serialIdField && data.serial_id === parseInt(serialIdField[1])

      if (data.exists && !isExistingUnchanged) {
        input.classList.add('border-red-500')
        if (!input.nextElementSibling?.classList.contains('error-message')) {
          const error = document.createElement('p')
          error.className = 'text-xs text-red-500 mt-1 error-message'
          error.textContent = `Serial number ${input.value} is already in use`
          input.parentNode.insertBefore(error, input.nextSibling)
        }
      } else {
        input.classList.remove('border-red-500')
        const error = input.nextElementSibling
        if (error?.classList.contains('error-message')) {
          error.remove()
        }
      }
    } catch (error) {
      console.error('Error checking serial number:', error)
    }
  }

  validateAll() {
    let isValid = true
    const serialNumbers = new Set()

    this.inputTargets.forEach(input => {
      // Skip hidden (deleted) fields
      if (input.closest('[data-serial-id]')?.style.display === 'none') return

      const value = input.value.trim()

      // Check for duplicates within current form
      if (value && serialNumbers.has(value)) {
        isValid = false
        input.classList.add('border-red-500')
        if (!input.nextElementSibling?.classList.contains('error-message')) {
          const error = document.createElement('p')
          error.className = 'text-xs text-red-500 mt-1 error-message'
          error.textContent = 'Duplicate serial number'
          input.parentNode.insertBefore(error, input.nextSibling)
        }
      } else {
        if (value) serialNumbers.add(value)
      }

      // Check for other validation errors
      if (input.classList.contains('border-red-500')) {
        isValid = false
      }
    })

    this.submitButtonTarget.disabled = !isValid
    this.updateCounters()
  }

  addField(event) {
    const receivableId = event.currentTarget.dataset.receivableId
    const container = this.containerTargets.find(c => c.dataset.receivableId === receivableId)

    const fieldWrapper = document.createElement('div')
    fieldWrapper.className = 'flex items-center space-x-2 mt-2'
    fieldWrapper.innerHTML = `
      <div class="flex-1">
        <input type="text" 
               name="serials[${receivableId}][new][]" 
               class="block w-full rounded-md bg-space-700 border-space-600 text-space-100"
               placeholder="Enter serial number"
               data-serials-target="input"
               data-action="input->serials#validateField">
      </div>
      <button type="button" 
              class="text-space-400 hover:text-red-300"
              data-action="serials#removeField">
        <i class="fas fa-trash"></i>
      </button>
    `

    container.appendChild(fieldWrapper)
    const newInput = fieldWrapper.querySelector('input')
    newInput.focus()
    this.validateAll()
  }

  removeExistingField(event) {
    const button = event.currentTarget
    const wrapper = button.closest('[data-serial-id]')
    const serialId = wrapper.dataset.serialId

    // Enable the hidden delete field
    const deleteField = this.deleteFieldTargets.find(f => f.dataset.serialId === serialId)
    if (deleteField) {
      deleteField.disabled = false
    }

    // Hide the field but keep it in the DOM
    wrapper.style.display = 'none'

    this.validateAll()
    this.updateCounters()
  }

  removeField(event) {
    const wrapper = event.currentTarget.closest('.flex')
    wrapper.remove()
    this.validateAll()
    this.updateCounters()
  }

  updateCounters() {
    this.counterTargets.forEach(counter => {
      const receivableId = counter.dataset.receivableId
      const container = this.containers.find(c => c.dataset.receivableId === receivableId)
      const total = parseInt(counter.dataset.total) || 0

      // Count visible fields with values
      const filledInputs = Array.from(container.querySelectorAll('input[type="text"]'))
        .filter(input => {
          const wrapper = input.closest('[data-serial-id]')
          return (!wrapper || wrapper.style.display !== 'none') && input.value.trim().length > 0
        })

      counter.innerHTML = `<span class="font-medium">${filledInputs.length}</span> of <span class="font-medium">${total}</span> serial numbers entered`
      counter.classList.toggle('text-yellow-500', filledInputs.length !== total)
    })
  }

  get containers() {
    return this.containerTargets
  }
}