import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "expectedQuantity",
    "receivedQuantity",
    "submitButton",
    "singleCondition",
    "splitConditions",
    "splitQuantity",
    "splitTotal"
  ]

  connect() {
    this.validateAll()
  }

  toggleSplit(event) {
    const itemId = event.target.dataset.itemId
    const singleCondition = this.singleConditionTargets.find(el => el.dataset.itemId === itemId)
    const splitConditions = this.splitConditionsTargets.find(el => el.dataset.itemId === itemId)
    const isChecked = event.target.checked

    if (isChecked) {
      // Store current condition before hiding
      const currentCondition = singleCondition.querySelector('select').value
      singleCondition.classList.add('hidden')
      splitConditions.classList.remove('hidden')

      // Pre-fill split with current condition if any
      if (currentCondition) {
        const splitInput = splitConditions.querySelector(`input[data-condition="${currentCondition}"]`)
        if (splitInput) {
          splitInput.value = this.expectedQuantityTargets.find(el => el.dataset.itemId === itemId).value
          this.updateSplitTotal({ target: splitInput })
        }
      }
    } else {
      singleCondition.classList.remove('hidden')
      splitConditions.classList.add('hidden')

      // Calculate predominant condition from splits
      const inputs = splitConditions.querySelectorAll('[data-receiving-target="splitQuantity"]')
      let maxQty = 0
      let predominantCondition = 'good'

      inputs.forEach(input => {
        const qty = parseInt(input.value) || 0
        if (qty > maxQty) {
          maxQty = qty
          predominantCondition = input.dataset.condition
        }
      })

      // Set the single condition select to the predominant condition
      singleCondition.querySelector('select').value = predominantCondition
    }
  }

  updateSplitTotal(event) {
    const itemId = event.target.dataset.itemId
    const splitFields = this.splitQuantityTargets.filter(el => el.dataset.itemId === itemId)
    const totalEl = this.splitTotalTargets.find(el => el.dataset.itemId === itemId)

    let total = 0
    splitFields.forEach(input => {
      total += parseInt(input.value) || 0
    })

    totalEl.textContent = total

    const expectedInput = this.expectedQuantityTargets.find(el => el.dataset.itemId === itemId)
    const expectedQty = parseInt(expectedInput.value)

    // Validate total against expected quantity
    if (total > expectedQty) {
      event.target.value = parseInt(event.target.value) - (total - expectedQty)
      this.updateSplitTotal(event) // Recalculate
    }

    this.validateAll()
  }

  updateExpected(event) {
    const input = event.target
    const maxQty = parseInt(input.dataset.maxQty)
    const value = parseInt(input.value)

    if (value > maxQty) {
      input.value = maxQty
    }

    // Update the corresponding received quantity max value
    const receivedInput = this.findReceivedInput(input)
    if (receivedInput && parseInt(receivedInput.value) > value) {
      receivedInput.value = value
    }

    this.validateAll()
  }

  updateReceived(event) {
    const input = event.target
    const expectedInput = this.findExpectedInput(input)
    const expectedQty = parseInt(expectedInput.value)
    const value = parseInt(input.value)

    if (value > expectedQty) {
      input.value = expectedQty
    }

    this.validateAll()
  }

  validateAll() {
    let isValid = true

    // Validate quantity inputs
    this.expectedQuantityTargets.forEach((expectedInput, index) => {
      const receivedInput = this.receivedQuantityTargets[index]
      const expectedQty = parseInt(expectedInput.value)
      const receivedQty = parseInt(receivedInput.value)
      const maxQty = parseInt(expectedInput.dataset.maxQty)

      if (expectedQty > maxQty || expectedQty < 0) isValid = false
      if (receivedQty > expectedQty || receivedQty < 0) isValid = false
    })

    // Validate split quantities
    const splitCheckboxes = document.querySelectorAll('[data-action*="receiving#toggleSplit"]')
    splitCheckboxes.forEach(checkbox => {
      if (checkbox.checked) {
        const itemId = checkbox.dataset.itemId
        const splitFields = this.splitQuantityTargets.filter(el => el.dataset.itemId === itemId)
        const expectedInput = this.expectedQuantityTargets.find(el => el.dataset.itemId === itemId)
        const expectedQty = parseInt(expectedInput.value)

        let total = 0
        splitFields.forEach(input => {
          total += parseInt(input.value) || 0
        })

        if (total !== expectedQty) isValid = false
      }
    })

    this.submitButtonTarget.disabled = !isValid
  }

  findReceivedInput(expectedInput) {
    const index = this.expectedQuantityTargets.indexOf(expectedInput)
    return this.receivedQuantityTargets[index]
  }

  findExpectedInput(receivedInput) {
    const index = this.receivedQuantityTargets.indexOf(receivedInput)
    return this.expectedQuantityTargets[index]
  }
}