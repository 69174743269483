import { application } from "./application"
// Core controllers
import AnimationController from "./animation_controller"
import FlashController from "./flash_controller"
import ProgressController from "./progress_controller"
import SidebarController from "./sidebar"
import ThemeSwitcherController from "./theme_switcher_controller"
import TabsController from "./tabs_controller"
import ImageUploadController from './image_upload_controller'
import TrixController from './trix_controller'
import ProductController from './product_controller'
import ToggleController from "./toggle_controller"
import BrandFormController from "./brand_form_controller"
import ProductSyncController from "./product_sync_controller"
// Third party
import RailsNestedForm from '@stimulus-components/rails-nested-form'
// Payment controllers
import StripeController from "./stripe_controller"
import PaymentsController from "./payments_controller"
// Builder controllers
import OrderFormController from "./builder/order_form_controller"
import OrderItemController from "./builder/order_item_controller"
import OrderTotalsController from "./builder/order_totals_controller"
import ProductSearchController from "./builder/product_search_controller"
import CustomerSearchController from "./builder/customer_search_controller"
import AdditionalItemsController from "./builder/additional_items_controller"
import CustomItemController from "./builder/custom_item_controller"
import PaymentDialogController from "./payment_dialog_controller"

import DepartmentRolesController from "./department_roles_controller"

import FormTabsController from './form_tabs_controller'

import MessengerController from './messenger_controller'

import POProductSearchController from "./po_product_search_controller"
import POOrderTotalsController from "./po_order_totals_controller"
import POTabsController from "./po_tabs_controller"

import NotificationsController from "./notifications_controller"
//import TimeController from "./time_controller"

import ReceivingController from "./receiving_controller"
import ShippingController from "./shipping_controller"
import SerialsController from "./serials_controller"

import Dropdown from '@stimulus-components/dropdown'


// Register core controllers
application.register("animation", AnimationController)
application.register("flash", FlashController)
application.register("progress", ProgressController)
application.register("sidebar", SidebarController)
application.register("theme-switcher", ThemeSwitcherController)
application.register("tabs", TabsController)
application.register("image-upload", ImageUploadController)
application.register("trix", TrixController)
application.register("product", ProductController)
application.register("toggle", ToggleController)
application.register("brand-form", BrandFormController)
application.register("product-sync", ProductSyncController)
// Register third party
application.register('nested-form', RailsNestedForm)
// Register payment controllers
application.register("stripe", StripeController)
application.register("payments", PaymentsController)
// Register builder controllers
application.register("order-form", OrderFormController)
application.register("order-item", OrderItemController)
application.register("order-totals", OrderTotalsController)
application.register("product-search", ProductSearchController)
application.register("customer-search", CustomerSearchController)
application.register("additional-items", AdditionalItemsController)
application.register("custom-item", CustomItemController)
application.register("payment-dialog", PaymentDialogController)

application.register("department-roles", DepartmentRolesController)

application.register("form-tabs", FormTabsController)

application.register("messenger", MessengerController)

application.register("po-product-search", POProductSearchController)
application.register("po-order-totals", POOrderTotalsController)
application.register("po-tabs", POTabsController)

application.register("notifications", NotificationsController)
//application.register("time", TimeController)

application.register("receiving", ReceivingController)
application.register("shipping", ShippingController)
application.register("serials", SerialsController)

application.register("dropdown", Dropdown)

application.register('dropdown', Dropdown)