import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["quantityInput", "totalItems"]

  connect() {
    this.updateTotals()
  }

  updateTotals() {
    const total = this.quantityInputTargets
      .map(input => parseInt(input.value) || 0)
      .reduce((sum, val) => sum + val, 0)

    this.totalItemsTarget.textContent = total
  }
}