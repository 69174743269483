if (!("Notification" in window)) {
  console.error("This browser does not support desktop notification");
}

function urlBase64ToUint8Array(base64String) {
  try {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  } catch (e) {
    console.error('Error converting base64 to Uint8Array:', e);
    throw e;
  }
}

export async function requestNotifications() {
  try {
    console.log('Requesting notification permission...');
    const permission = await Notification.requestPermission();
    console.log('Permission:', permission);

    if (permission === 'granted') {
      console.log('Registering service worker...');
      const registration = await navigator.serviceWorker.register('/service-worker.js');
      console.log('Service Worker registered:', registration);

      let vapidPublicKey = window.vapidPublicKey;
      console.log('Raw VAPID Key:', vapidPublicKey);

      // let applicationServerKey;
      // try {
      //   applicationServerKey = urlBase64ToUint8Array(vapidPublicKey);
      //   console.log('Converted VAPID Key:', applicationServerKey);
      // } catch (e) {
      //   console.error('VAPID key conversion failed:', e);
      //   throw e;
      // }

      console.log('Getting subscription...');
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey
      });

      console.log('Sending to server...');
      const response = await fetch('/push_subscriptions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          subscription: {
            endpoint: subscription.endpoint,
            keys: {
              p256dh: btoa(String.fromCharCode.apply(null,
                new Uint8Array(subscription.getKey('p256dh')))),
              auth: btoa(String.fromCharCode.apply(null,
                new Uint8Array(subscription.getKey('auth'))))
            }
          }
        })
      });

      if (!response.ok) {
        const error = await response.text();
        throw new Error(`Server error: ${error}`);
      }

      console.log('Server response:', response);
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error in requestNotifications:', error);
    return false;
  }
}